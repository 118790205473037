import { Prism } from "prism-react-renderer";
import React from "react";
import { MathJaxContext } from "better-react-mathjax";

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-csharp.js");
require("prismjs/components/prism-java.js");

const mathJaxConfig = {
  //loader: { load: ["[tex]/html"] },
  tex: {
    //packages: { "[+]": ["html"] },
    inlineMath: [
      ["$", "$"],
      //["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      //["\\[", "\\]"]
    ],
  },
};

export const wrapRootElement = ({ element }) => {
  return <MathJaxContext config={mathJaxConfig}>{element}</MathJaxContext>;
};
