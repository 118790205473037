import { merge } from "theme-ui";
import defaultTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const baseFonts = {
  sans: `ui-sans-serif,system-ui,-apple-system,"Segoe UI",Roboto,Ubuntu,Cantarell,"Noto Sans",sans-serif,BlinkMacSystemFont,"Helvetica Neue",Arial,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
};

const navSize = "5.5rem";

const theme = merge(defaultTheme, {
  colors: {
    slate: [
      "",
      "#f8fafc",
      "#f1f5f9",
      "#e2e8f0",
      "#cbd5e1",
      "#94a3b8",
      "#64748b",
      "#475569",
      "#334155",
      "#1e293b",
      "#0f172a",
    ],
    primary: "rgb(17,24,39)",
    secondary: "rgb(117,121,126)",
    backgroundTransparent: "rgba(255,255,255,0.8)",
    buttonBackground: "rgb(225,234,255)",
    text: "rgb(17,24,39)",
    secondaryHeaderColor: "rgb(26,86,219)",
    toggleIcon: "rgb(17,24,39)",
    modes: {
      dark: {
        primary: "white",

        secondary: "rgb(159,170,187)",
        backgroundTransparent: "rgba(26, 32, 44, 0.8)",
        buttonBackground: "rgba(43,50,59)",
        secondaryHeaderColor: "rgb(119,197,227)",
        toggleIcon: "white",
      },
    },
  },

  fonts: {
    ...baseFonts,
    body: baseFonts.sans,
  },
  fontWeights: {
    lightnorm: 350,
  },
  lineHeights: {
    body: "1.875",
  },

  layout: {
    container: {
      maxWidth: null,
      padding: "0",
      paddingTop: navSize,
    },
    main: {
      maxWidth: `1200px`,
      mx: "auto",
    },
    header: {
      height: navSize,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "divide",
      backgroundColor: "backgroundTransparent",
      zIndex: "50",
      backdropFilter: "blur(12px)",
    },
    footer: {
      variant: "layout.main",
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: "divide",
      paddingY: 4,
    },
    content: {
      maxWidth: `816px`,
      mx: "auto",
    },
  },

  text: {
    heading: {
      color: "secondaryHeaderColor",
    },
  },

  styles: {
    root: {
      fontWeight: "lightnorm",
    },
    a: {
      textDecorationLine: "underline",
      textDecorationThickness: "1px",
      textUnderlineOffset: "5px",
      fontWeight: "medium",
      "&:hover": {
        color: "secondary",
      },
    },
    h1: {
      color: "text.heading",
    },
    h2: {
      fontSize: 3,
    },
    h3: {
      fontSize: 2,
    },
    h4: {
      fontSize: 1,
      mt: 4,
    },
    h5: {
      fontSize: 1,
    },
    h6: {
      fontSize: 1,
    },
    hr: {
      marginTop: 4,
      marginBottom: 2,
    },
    strong: {
      fontWeight: "medium",
    },
  },
});

export default theme;
